<template>
  <div class="manage-investor-detail" :class="{ skeleton: !state.loaded }">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <span>후원자 정보</span>
          </div>
          <div class="card-body">
            <table class="table">
              <tbody>
              <tr>
                <th>
                  <span>참여 날짜</span>
                </th>
                <td>
                  <span>{{ state.investor.regDate }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>결제 상태</span>
                </th>
                <td>
                  <span>{{ state.investor.paymentState }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>후원자 아이디</span>
                </th>
                <td>
                  <span>{{ state.investor.memberId }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>휴대전화</span>
                </th>
                <td>
                  <span>{{ state.investor.investorHp }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>후원자명</span>
                </th>
                <td>
                  <span>{{ state.investor.memberName }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-header">
            <span>리워드</span>
          </div>
          <div class="card-body">
            <table class="table">
              <tbody>
              <tr v-if="!state.investorRewardList.length">
                <th>
                  <span>참여 리워드</span>
                </th>
                <td>
                  <span v-if="!state.investorRewardList.length">리워드 없이 펀딩</span>
                </td>
              </tr>
              <template v-for="(r1, idx1) in state.investorRewardList">
                <tr :key="'a' + idx1">
                  <th>
                    <span>참여 리워드</span>
                  </th>
                  <td>
                  <span v-for="(r2, idx2) in state.projectRewardList" :key="idx2">
                    <template v-if="Number(r1.rewardSeq) === Number(r2.rewardSeq)">{{ r2.title }}</template>
                  </span>
                  </td>
                </tr>
                <tr :key="'b' + idx1">
                  <th>
                    <span>수량</span>
                  </th>
                  <td>
                    <span>{{ $lib.getNumberFormat(r1.cnt) }}개</span>
                  </td>
                </tr>
                <tr :key="'c' + idx1">
                  <th>
                    <span>리워드 옵션</span>
                  </th>
                  <td>
                    <span>{{ $lib.isJsonString(r1.rewardOption) ? $lib.getStringFromObj(JSON.parse(r1.rewardOption)) : r1.rewardOption || "(N/A)" }}</span>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <span>결제 정보</span>
          </div>
          <div class="card-body">
            <table class="table">
              <tbody>
              <tr>
                <th>
                  <span>결제 방법</span>
                </th>
                <td>
                  <span>{{ state.investor.paymethodText }}</span>
                </td>
              </tr>
              <template v-if="state.investorRewardList.length">
                <tr>
                  <th>
                    <span>리워드 참여 금액</span>
                  </th>
                  <td>
                    <span>{{ $lib.getNumberFormat(state.investor.expenseAmt - state.investor.addAmt - state.investor.deliveryAmt) }}원</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>추가 참여 금액</span>
                  </th>
                  <td>
                    <span>{{ $lib.getNumberFormat(state.investor.addAmt) }}원</span>
                  </td>
                </tr>
              </template>
              <tr v-else>
                <th>
                  <span>리워드 없이 참여 금액</span>
                </th>
                <td>
                  <span>{{ $lib.getNumberFormat(state.investor.expenseAmt) }}원</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>포인트 사용 금액</span>
                </th>
                <td>
                  <span>{{ $lib.getNumberFormat(state.investor.omcpay) }}원</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>쿠폰 사용 금액</span>
                </th>
                <td>
                  <span>{{ $lib.getNumberFormat(state.investor.couponPoint) }}원</span>
                </td>
              </tr>
              <template v-if="state.investorRewardList.length">
                <tr>
                  <th>
                    <span>배송비</span>
                  </th>
                  <td>
                    <span>{{ $lib.getNumberFormat(state.investor.deliveryAmt) }}원</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>총 참여 금액</span>
                  </th>
                  <td>
                    <span class="text-danger">{{ $lib.getNumberFormat(state.investor.expenseAmt - state.investor.deliveryAmt) }}원</span>
                  </td>
                </tr>
              </template>
              <tr>
                <th>
                  <span>총 결제 금액</span>
                </th>
                <td>
                  <span class="text-danger">{{ $lib.getNumberFormat(state.investor.expenseAmt - (state.investor.omcpay + state.investor.couponPoint)) }}원</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card" v-if="state.investorRewardList.length">
          <div class="card-header">
            <span>수령 정보</span>
          </div>
          <div class="card-body">
            <table class="table">
              <tbody>
              <tr>
                <th>
                  <span>받는 분</span>
                </th>
                <td>
                  <span>{{ state.investor.receiveName }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>휴대전화</span>
                </th>
                <td>
                  <span>{{ state.investor.hp }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>이메일</span>
                </th>
                <td>
                  <span>{{ state.investor.email }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>주소</span>
                </th>
                <td>
                  <span>({{ state.investor.postno }}) {{ state.investor.addr1 }} {{ state.investor.addr2 }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>메모</span>
                </th>
                <td>
                  <span>{{ state.investor.memo }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Card from "@/components/Card";
import Wait from "@/components/Wait";
import http from "@/scripts/http";
import router from "@/scripts/router";
import Pagination from "@/components/Pagination";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "pageManageInvestor";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Pagination, Wait, Card},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      state.loaded = false;
      http.get(`/api/maker/${projectType}/${projectSeq}/investors/${investorSeq}`).then(({data}) => {
        state.loaded = true;
        state.investor = data.body.investor;
        state.investor.addAmt = window.Number(state.investor.addAmt) || 0;
        state.investor.deliveryAmt = window.Number(state.investor.deliveryAmt) || 0;
        state.investor.expenseAmt = window.Number(state.investor.expenseAmt) || 0;
        state.investor.omcpay = window.Number(state.investor.omcpay) || 0;
        state.investor.couponPoint = window.Number(state.investor.couponPoint) || 0;

        state.investorRewardList = data.body.investorRewardList;
        state.projectRewardList = data.body.projectRewardList;
      });
    });

    const state = reactive({
      loaded: false,
      investor: {
        regDate: "0000-00-00",
        paymentState: "Wait a moment",
        memberId: "Wait a moment",
        investorHp: "00000000000",
        hp: "00000000000",
        email: "wait@moment",
        postno: "00000",
        addr1: "Wait a moment",
        addr2: "Wait a moment",
        memberName: "Wait a moment",
        paymethodText: "Wait a moment",
        receiveName: "Wait a moment",
        memo: "Wait a moment",
      },
      investorRewardList: [{
        rewardSeq: 0,
        cnt: 0,
        rewardOption: "Please wait a moment",
      }],
      projectRewardList: [{
        rewardSeq: 0,
        title: "Wait a moment"
      }],
    });
    const projectType = router.app.$route.params.projectType;
    const projectSeq = router.app.$route.params.projectSeq;
    const investorSeq = router.app.$route.params.investorSeq;

    const openMessageModal = (investor) => {
      store.commit("openModal", {
        name: "MessageWrite",
        params: {
          type: "send",
          members: [{
            seq: investor.memberSeq,
            name: investor.memberName
          }]
        }
      });
    };

    return {component, state, openMessageModal};
  }
})
;
</script>

<style lang="scss" scoped>
.manage-investor-detail {
  > .row > div {
    > .card {
      box-shadow: none;
      border: $px1 solid #eee;

      > .card-header {
        background: #f8f9fe;
      }

      > .card-body {
        > table.table {
          border-bottom: 0 !important;

          th, td {
            border: 0;
            padding-top: $px10;
            padding-bottom: $px10;
            padding-left: 0;
            font-size: $px14;
          }

          td {
            > span {
              white-space: pre-line;
            }
          }
        }
      }
    }
  }

  &.skeleton {
    .row > div > .card > {
      .card-header > span {
        @include skeleton;
      }

      .card-body > table.table {
        th, td {
          > span {
            @include skeleton;
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    > .row > div > .card {
      > .card-header {
        padding: $px15;
      }

      > .card-body {
        padding: $px10 $px15;
      }
    }
  }
}
</style>